.header {
    background-color: rgb(252, 182, 20);
    padding: 10px 40px;
    max-width: 100% !important;
    box-shadow: none !important;
}

.newSubAdmin .MuiOutlinedInput-root {
    background-color: #ffffff;
}
.newSubAdmin.bgWhiteInp {
    background-color: transparent !important;
    height: 75px;
}
.account_dropdown {
    top: 60px !important;
}
.username {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@media (max-width: 600px) {
    .header {
        padding: 10px;
    }
    .username {
        max-width: 200px;
    }
}
