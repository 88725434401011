.listBox {
    /* border: 1px solid #ccc; */
    border: 1px solid rgba(36, 31, 37, 0.22);
    border-radius: 5px;
    padding: 12px 10px 12px 30px;
    min-height: 75px;
    display: flex !important;
    align-items: center;
    margin-bottom: 18px;
}
.listBox.edit_listBox {
    /* border: 1px solid rgb(75, 48, 88); */
    border: 1.5px solid rgb(75, 48, 88, 0.5);
}
.item {
    flex: 1;
    padding-right: 20px;
}
.itemIcon {
    width: 50px;
}
.edit {
    width: 100%;
    display: flex;
}
.cancel {
    max-width: 200px;
}
@media screen and (max-width: 600px) {
    .listBox {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        position: relative;
    }
    .item {
        width: calc(100% - 50px);
        margin-bottom: 10px;
        padding-right: 0;
    }
    .edit .item {
        width: 100%;
    }
    .cancel {
        max-width: 100%;
    }
    .itemIcon {
        width: 50px;
        position: absolute;
        top: 0;
        right: 0;
    }
    .edit {
        flex-direction: column;
    }
}
