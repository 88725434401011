.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* ===============================theme========================== */
@font-face {
    font-family: 'apercupromedium';
    src: url('../src/fonts/Apercu-Pro-Medium/ApercuProMedium.woff'),
        url('../src/fonts/Apercu-Pro-Medium/ApercuProMedium.woff2');
}

@font-face {
    font-family: 'apercuproregular';
    src: url('../src/fonts/ApercuPro-Regular/74c3c29282b604a9eed88ac6ede61463.woff'),
        url('../src/fonts/ApercuPro-Regular/74c3c29282b604a9eed88ac6ede61463.woff2');
}

@font-face {
    font-family: 'quincy_cf_bold';
    src: url('../src/fonts/quincy_cf_bold/quincy_cf_bold-webfont.woff'),
        url('../src/fonts/quincy_cf_bold/quincy_cf_bold-webfont.woff2');
}

.quincy_cf_bold {
    font-family: 'quincy_cf_bold' !important;
}
.login_heading {
    font-family: 'quincy_cf_bold' !important;
    font-size: 55px !important;
}
.sub_heading {
    font-family: 'quincy_cf_bold' !important;
    font-size: 35px !important;
}
.bgWhiteInp {
    background-color: #ffffff !important;
    border-radius: 5px !important;
}
.link {
    color: rgb(36, 31, 37) !important;
}
.para {
    color: rgba(36, 31, 37, 0.5);
    margin: 4px 0;
    font-size: 16px;
}
.sorting {
    font-family: 'apercupromedium' !important;
    font-size: 20px !important;
}
.MuiButton-root,
.MuiButton-contained {
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15) !important;
}
.searchInp {
    display: flex;
    align-items: center;
    background-color: rgba(36, 31, 37, 0.14);
    border-radius: 5px;
    position: relative;
    min-width: 100%;
    padding: 13px 15px;
    min-height: 51px;
}
.searchInp input {
    font-family: 'apercuproregular';
    font-size: 16px;
    background-color: rgba(36, 31, 37, 0);
    border-style: none;
    flex: 1;
    padding-right: 10px;
}
.searchInp input:focus {
    border-style: none;
    outline: none;
}
.searchInp .searchIcon {
    /* width: 22px;
  height: 22px; */
    display: flex;
    align-items: center;
}

.searchInp .searchIcon .MuiSvgIcon-root {
    /* 22pt--> 29px */
    height: 29px;
    width: 29px;
}

.MuiPaper-elevation8 {
    /* box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12); */
    box-shadow: 0px 1px 4px 2px rgba(75, 48, 88, 0.1) !important;
}
.text_center {
    text-align: center !important;
}
.dialog_btn_center {
    justify-content: center !important;
    padding: 20px !important;
}
.dialog_btn_center button:first-child {
    margin-right: 15px;
}
.wrapper {
    flex: 1 1 auto;
    margin: 0 auto;
    display: flex;
    overflow: hidden;
    max-width: 1060px;
    padding-top: 136px;
    flex-direction: column;
    margin-bottom: 20px;
}

.MuiFormHelperText-root.Mui-error {
    color: rgba(36, 31, 37, 0.5) !important;
}

.MuiFormHelperText-root {
    line-height: 1.26 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
    transition: background-color 5000s ease-in-out 0s;
}
.searchClear {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
}
.small_btn {
    min-width: 110px !important;
    line-height: 2.35 !important;
    font-family: 'apercupromedium' !important;
    font-size: 16px !important;
    border-radius: 8px !important;
}
.newSubAdmin .MuiOutlinedInput-root {
    border-radius: 8px !important;
}
.newSubAdmin .MuiOutlinedInput-input {
    /* padding: 16px 14px !important; */
}
.fade_icon {
    opacity: 0.5;
}
@media screen and (max-width: 1090px) {
    .wrapper {
        padding: 110px 10px 0;
    }
}
@media screen and (max-width: 600px) {
    .loginTitle .login_heading {
        font-size: 35px !important;
    }
    .sub_heading {
        font-size: 20px !important;
    }
    .searchInp {
        min-width: 100%;
    }
    .para {
        font-size: 14px;
    }
}
