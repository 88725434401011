.listBox {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 12px 30px;
    min-height: 75px;
    display: flex !important;
    align-items: center;
    margin-bottom: 18px;
}
.item {
    flex: 1;
    padding-right: 20px;
}
.itemIcon {
    width: 50px;
}
.delete_btn {
    border: 2px solid rgb(223, 133, 0) !important;
    border-radius: 8px !important;
    text-transform: capitalize !important;
}
.active {
    background-color: rgb(75, 48, 88);
    color: #ffffff;
}
.editFiend {
    max-width: 354px;
    margin: 0 auto;
}
.buttonWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 354px;
    margin: 0 auto;
}
.closeButton {
    position: absolute;
    top: 0;
    right: 0;
}

.manageUsersBox .sub_heading {
    text-align: left;
}

@media screen and (max-width: 600px) {
    .listBox {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }
    .listBox button {
        margin-top: 10px;
    }
}
